import BenefitsTokenData from '../Assets/benefitsTokenData.csv';
import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import Papa from 'papaparse';
import toast from 'react-hot-toast';
import BenefitsExportGuide2024 from '../Assets/BenefitsExportGuide2024.2.pdf';
import BenefitsExportGuide2023 from '../Assets/BenefitsExportGuide2023.2.pdf';

const BenefitTokens = () => {
  const openExportGuide1 = () => {
    window.open(BenefitsExportGuide2023, '_blank');
  };

  const openExportGuide2 = () => {
    window.open(BenefitsExportGuide2024, '_blank');
  };

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(''); // State for search text

  const columns = React.useMemo(
    () => [
      {
        Header: 'Token',
        accessor: 'token',
        Cell: ({ value }) => (
          <div>{value} <button onClick={() => {
            navigator.clipboard.writeText(value); 
            toast('Value copied to clipboard',
              {
                icon: '📋',
                duration: 2000,
                position: 'bottom-center',
                style: {
                  borderRadius: '10px',
                  border: '1px solid #fff',
                  background: '#333',
                  color: '#fff',
                },
              }
            ); }} style={{ marginLeft: '10px' }}>Copy</button></div>
        ),
      },
      { Header: 'Description', accessor: 'description' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  useEffect(() => {
    Papa.parse(BenefitsTokenData, {
      download: true,
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });
  }, []);

  // Filter data based on search text
  const filteredData = data.filter(row => {
    const searchWords = searchText.toLowerCase().split(" ");
    return searchWords.every(word =>
      Object.values(row).some(value =>
        value.toString().toLowerCase().includes(word)
      )
    );
  });  

  return (
    <>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="table-container">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              // Only prepare and render rows that match the filtered data
              if (filteredData.includes(row.original)) {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
      <div className="button-container">
        <button onClick={openExportGuide1}>Benefits Export Guide 2023</button>
        <button onClick={openExportGuide2}>Benefits Export Guide 2024</button>
      </div>
      
    </>
  );
};

export default BenefitTokens;
